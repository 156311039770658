.unsubscribeContainer {
    display: grid;
    justify-content: center;
    grid-template-rows: auto auto auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    width: 513px;
    height: 719px;
    text-align: center;
    gap: 48px;
    justify-content: center;
    align-items: center;
  }

  #confirmationContainer {
      width: 449px;
  }

  #unsubscribeButtonContainer{
    margin-top: 50px;
  }

  #unsubscribeButton {
    border-style: none;
    border-radius: 8px;
    width: 293px;
    height: 43px;
    background: #0091ea;
    color: #ffff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }

  #primaryText {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: #333333;
    margin: 0;
  }

  @media screen and (max-width: 850px) {
    .unsubscribeContainer {
      grid-template-rows: auto auto;
    }
    .textContainer {
      width: 350px;
      height: 494px;
      flex-direction: column;
      justify-content: center;
    }
    #confirmationContainer {
      width: 310px;
    }
    #unsubscribeButton {
      width: 343px;
    }
  }