#messageContainer p {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
  text-wrap: nowrap;
}

#reviewMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: center;
}

#buttonContainer {
  height: 130px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

#buttonContainer button {
  width: 343px;
  height: 43px;
  border-radius: 8px;
  background: #0091ea;
  color: #ffff;
  border-style: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.08750000596046448px;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  #feedbackSuccess {
    grid-row: 1/2;
  }

  #buttonContainer {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
