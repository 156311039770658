#feedbackFooter p {
  color: #888888;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

#feedbackFooter span {
  padding-left: 10px;
  padding-right: 10px;
}

#feedbackFooter a {
  color: #888888;
  text-decoration: none;
}

#feedbackFooter {
  grid-row: 3/3;
  align-self: end;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
}

@media screen and (max-width: 850px) {
  #feedbackFooter {
    grid-row: 2/2;
    padding-left: 24px;
    padding-right: 24px;
  }
}
