#feedbackContainer {
  padding: 18px 40px 18px 40px;
  background-color: #0091ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

#feedbackLogo {
  height: 77px;
}

#feedbackHeaderText {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-wrap: nowrap;
}

@media screen and (max-width: 850px) {
  #feedbackContainer {
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  #feedbackLogo {
    display: flex;
    align-items: center;
  }

  #feedbackLogo img {
    width: 115px;
    height: 34px;
  }
}
