.contentContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-row: 2 / 3;
  align-items: center;
  justify-content: center;
}

#headerText {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

#feedbackPhone {
  width: 240px;
  height: 240px;
}

#starRating {
  box-shadow: 0px 4px 8px 0px #00000033;
  margin: 0 auto;
  width: 292px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#subHeader {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#subHeaderText {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

#starContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 260px;
}

#satisfaction {
  font-size: 14px;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#satisfaction p {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

#submitButtonContainer {
  margin-top: 50px;
}

#submitButton {
  border-style: none;
  border-radius: 8px;
  width: 293px;
  height: 43px;
  background: #0091ea;
  color: #ffff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

#submitButton:disabled {
  cursor: not-allowed;
  background: #999999;
  color: #ffff;
  font-size: 18px;
  font-weight: 600;
}

.star {
  width: 40px;
  height: 36px;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  #submitButtonContainer {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contentContainer {
    grid-row: 1 / 2;
  }
}
