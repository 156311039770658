body {
  height: 100vh;
  font-family: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

#gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 850px) {
  #gridContainer {
    display: grid;
    grid-template-columns: auto;
  }
}
