#feedbackFormContainer {
  display: grid;
  justify-content: center;
  grid-template-rows: auto auto auto;
}

@media screen and (max-width: 850px) {
  #feedbackFormContainer {
    grid-template-rows: auto auto;
  }
}
