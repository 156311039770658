#neutralRating {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-row: 2 / 3;
  align-items: center;
}

#neutralRatingContainer {
  width: 201px;
  height: 22px;
}

#neutralRatingContainer p {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
}

#neutralFeedbackMessage {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.92px;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 850px) {
  #neutralRating {
    grid-row: 1/2;
  }
}
