#negativeRating {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-row: 2 / 3;
  align-items: center;
}
#negativeMessage {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
}

#emailLink a {
  color: #0091ea;
}

#emailLink p {
  font-weight: 500;
}

#textAreaBoxContainer {
  box-sizing: border-box;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

#textAreaBox {
  width: 100%;
  max-width: 449px;
  height: 140px;
  padding: 12px;
  border: 1px solid #eeeeee;
  background: #ffffff;
  resize: none;
  margin-top: 25px;
  font-family: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;

  &:focus {
    border: 1px solid #0091ea;
    outline: none;
  }

  &.characterLimit {
    border: 1px solid #ef3824;
  }
}

#maxCount {
  color: #333333;

  &.maxCharacters {
    color: #ef3824;
  }
}

#characterMax {
  &.limitReached {
    color: #ef3824;
  }
}

#textCount {
  display: flex;
  font-size: 12px;
  height: 15px;
  flex-direction: row;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #333333;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  max-width: 500px;
}

#doneButtonContainer {
  height: 43px;
  border-radius: 8px 0px 0px 0px;
  margin-top: 25px;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

#doneButton {
  width: 100%;
  height: 43px;
  background: #0091ea;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.48px;
  letter-spacing: -0.087500006px;
  text-align: center;
  max-width: 480px;
  padding-left: 10px;
  padding-right: 10px;
}

#doneButton:disabled {
  cursor: not-allowed;
  background: #999999;
  color: #ffff;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 850px) {
  #negativeRating {
    grid-row: 1/2;
  }

  #textAreaBox {
    box-sizing: border-box;
    height: 166px;
  }

  #textCount {
    padding-left: 24px;
    padding-right: 24px;
  }

  #doneButton {
    box-sizing: border-box;
    width: 100%;
    max-width: 455px;
  }

  #textAreaBoxContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

  #doneButtonContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
